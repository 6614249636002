<template lang="pug">
.content
  section.container-fluid.m-t-20

    a-breadcrumb(separator=">")
      a-breadcrumb-item
        router-link(to="/") 控制台
      a-breadcrumb-item 附件管理
    
    .m-t-15.m-b-20
      h1.section-title.inline 附件管理
      a-button.m-l-15(size="small" @click="handleAdd") 新增附件
      
    .m-uploads(v-if="uploader")

    //- vc-waterfall.m-b-25(:data="table")
    vc-table.m-b-25(
      ref="table"
      :data="table"
      @change="handleTableChange")
    
    //- 附件编辑器
    vc-attachment-form(
      :visible="modal_form.show"
      :data="modal_form.data"
      @cancel="modal_form_cancel"
      @ok="modal_form_ok")

</template>

<script>
//- import { mapState } from 'vuex'

import columns from '@/config/column.config'
import vcTable from '@/components/vcTable'
import vcWaterfall from '@/components/vcWaterfall'
import vcAttachmentForm from '@/components/vcAttachmentForm'

export default {
  name: 'AttachmentManagement',
  components: {
    'vc-table': vcTable,
    'vc-waterfall': vcWaterfall,
    'vc-attachment-form': vcAttachmentForm
  },
  computed: {
  },
  data() {
    return {
      /*********************************************************
      * @description vc-attachment-form 对象
      *********************************************************/
      modal_form: {
        show: false,
        data: null
      },

      // 查询条件参数
      queryParam: {},

      /*********************************************************
      * @description vc-table 表格对象
      *********************************************************/
      table: {
        loading: false,
        size: 'default',   // default | middle | small
        columns: columns.attachment(this),
        source: [],
        //- scroll: { x: 830 },

        api: parameter => {
          return this.$http.get('attachment', {
            params: Object.assign(parameter, this.queryParam)
          })
        },
      
        // 批量操作
        actions: [
          { label: '删除', value: 'delete' },
        ],

        // 过滤
        // filters: [],
        
        // 额外
        // extra: [],
        
        // 分页器
        pagination: {
          total: 0,
          current: 1,
          pageSize: 25,
          pageSizeOptions: ['25', '45', '65'],
        },
      },

      //-
      uploader: false
    }
  },
  filters: {},
  methods: {
    /*********************************************************
     * @description vc-attachment-form 对象方法
     *********************************************************/
    modal_form_cancel() {
      this.modal_form.loading = false;
      this.modal_form.show = false;
    },
    modal_form_ok() {
      console.log('modal_form_ok')
    },

    /*********************************************************
     * @description vc-table 对象方法
     *********************************************************/
    async handleTableChange({ action, pagination, filters, sorter }) {
      if (action) {
        switch(action.do) {
          case 'delete':
            await this.$http.delete('attachment', { data: { ids: action.data } });
            this.$message.success('删除成功');
            this.$refs.table.refresh();
          break;
        }
        return;
      }

      // keyword: this.table.keyword
      console.log('handleTableChange', { action, pagination, filters, sorter })
    },
    
    //- 新增/编辑操作方法
    async handleAdd() {
      const route = this.$router.getRoutes().find(item => item.name === 'Attachment Add');
      if (route && this.$route.name !== 'Attachment Add') return this.$router.push({ name: 'Attachment Add' });
      
      this.uploader = !this.uploader;
    },
    async handleEdit(id) {
      const route = this.$router.getRoutes().find(item => item.name === 'Attachment Edit');
      if (route && this.$route.name !== 'Attachment Edit') return this.$router.push({ name: 'Attachment Edit', params: { id } });
      
      this.modal_form.title = '编辑标签';
      this.modal_form.show = true;
      this.modal_form.loading = true;
      this.modal_form.footer = null;
      this.modal_form.data = await this.getDetail(id);
      this.modal_form.loading = false;
      this.modal_form.footer = undefined;
    },

    /*********************************************************
     * @description 获取单个数据
     *********************************************************/
    async getDetail(id) {
      return await this.$http.get(`attachment/${id}`);
    },

    /*********************************************************
     * @description 删除单个数据
     *********************************************************/
    async deleteData(id) {
      await this.$http.delete(`attachment/${id}`);
      this.$refs.table.refresh();
    },
  },
  created() {
    //- this.tableOption()
    //- getRoleList({ t: new Date() })
  },
}
</script>

<style lang="less" scoped>
.m-uploads {
  border: dotted 1px #ccc;
  padding: 50px;
}
</style>