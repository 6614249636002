<template lang="pug">
  a-modal(
    title='附件编辑'
    :visible="visible"
    @cancel="() => { $emit('cancel') }"
    @ok="() => { $emit('ok') }")
    template(v-if="data")
      img.full-width(:src="data.src || data.guid")
      
      p 上传于： {{ data.created_at }}
      p 上传者： {{ data.creator ? data.creator.display_name : '--' }}
      p 文件名： {{ data.name }}
      p 文件类型： {{ data.mime_type }}
      p 文件大小： {{ data.size }} KB
      p 分辨率： ? × ?像素

      p 状态： {{ data.status }}

      //- pre {{ data }}

</template>

<script>
export default {
  name: 'vcAttachmentForm',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: null
    }
  },
}
</script>